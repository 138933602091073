<div class="workshop-container-wrapper__row__detail_schedule_visitors__group">
  <img *ngIf="attendees.length > 0" class="workshop-container-wrapper__row__detail_schedule_visitors__group__icon"
       src="assets/images/workshop_group.svg"/>
  <div class="workshop-container-wrapper__row__detail_schedule_visitors__group__chips">
    <mat-chip-listbox>
      <mat-chip-option [disabled]="!isRemoval" *ngFor="let name of attendees.slice(0, 3)"
                       (removed)="removeAttendees(name)"
                       [removable]="isRemoval">{{name}}
        <mat-icon *ngIf="isRemoval" matChipRemove>cancel</mat-icon>
      </mat-chip-option>
      <mat-chip-option  [disabled]="!isRemoval" *ngIf="attendees.length > 3">+{{attendees.length - 3}}</mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
