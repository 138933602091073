import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/assets/scripts/service-worker.js')
    .then(registration => {
      console.log('Service Worker registered:', registration);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
