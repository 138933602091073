import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataSharingService } from '../shared/services/data-sharing.service';

@Component({
  selector: 'app-fourzerofour',
  templateUrl: './fourzerofour.component.html',
  styleUrls: ['./fourzerofour.component.scss']
})
export class FourzerofourComponent {
  clientRole:string;
  constructor(private dataSharingService:DataSharingService){

  }
  ngOnInit(){
    console.log(this.dataSharingService.clientRole);
    this.clientRole=this.dataSharingService.clientRole;
    console.log(this.clientRole);
    this.clientRole=this.clientRole.toLowerCase();
  }
  redirect(){
    window.location.href=`${environment.svagriha_url}/${this.clientRole}`;
  }
}
