<div class="workshop-admin-view-wrapper">
  <div class="workshop-admin-view-wrapper__header">
    <div class="workshop-admin-view-wrapper__header__title">
      <label class="wavw__header__title--name">{{workshopSiteVisit.name}}</label>
      <label class="wavw__header__title--act">{{subTitle}}</label>
    </div>
    <label class="wavw__header--value">{{siteVisitType}} {{type}}</label>
  </div>
  <div class="workshop-admin-view-wrapper__purpose">
    <div class="workshop-admin-view-wrapper__purpose--status">
      <label class="wavw__purpose--status-type">{{type}}</label>
      <div class="wavw__purpose--status-date">
        <span class="wavw__purpose--status-date--text">{{workshopRes?.res?.workshopWeek}}</span>
        <span
          class="wavw__purpose--status-date--remaining_days">{{workshopRes?.res?.remainingDays}} days from today</span>
      </div>
    </div>
    <label  [ngClass]="(workshopRes?.status === 'Confirmed' || workshopRes?.status === 'Done') ? 'wavw__purpose--schedule-status--text_confirm' : 'wavw__purpose--schedule-status--text__normal'">{{workshopRes?.status}}</label>
  </div>

  <div class="workshop-admin-view-wrapper-row">
    <label class="wavw-row__title">Griha Coordinator(Additional)</label>
    <div class="wavw-row-coordinator">
      <label class="wavw-row__rep-name">{{workshopSiteVisit.name}}</label>
    </div>
  </div>

  <div class="workshop-admin-view-wrapper-row">
    <label class="wavw-row__title">Client Representative</label>
    <div class="wavw-row-client-rep">
      <img class="wavw-row__person-icon"
                  src="assets/images/workshop_group.svg"/><span
      class="wavw-row__rep-email">{{workshopSiteVisit.client_representative_email}}</span>
      <span class="wavw-row__rep-phone">Phone No: {{workshopSiteVisit.client_representative_phone}}</span>
    </div>
  </div>

  <div *ngIf="isWorkshop" class="workshop-admin-view-wrapper-row">
    <label class="wavw-row__title">Attendees</label>
    <div class="view-status-wrapper-row__value-container">
      <app-meeting-attendees [attendees]="workshopSiteVisit.attendees"></app-meeting-attendees>
    </div>
  </div>

  <div class="workshop-admin-view-wrapper-upload" *ngIf="isWorkshop">
    <label class="workshop-admin-view-wrapper-upload__title">Upload Check List</label>
    <div class="workshop-admin-view-wrapper-upload__box" fileDragDrop (fileDropped)="onFileDroppedCheckList($event)">
      <input type="file" #fileDropRef id="fileDropRef" multiple  (change)="checkListFileBrowseHandler($event)" />
      <div class="workshop-admin-view-wrapper-upload__box--icon">
        <img src="assets/images/upload_file.svg">
      </div>

      <div class="workshop-admin-view-wrapper-upload__box--text">
        <span class="workshop-admin-view-wrapper-upload__box--text--1">Drag & drop your files here or</span>
        <span class="workshop-admin-view-wrapper-upload__box--text--2">
          <label class="workshop-admin-view-wrapper-upload__box--text--2" for="fileDropRef">choose files</label>
        </span>
      </div>
      <label class="workshop-admin-view-wrapper-upload__max-size">10 MB max file size</label>
    </div>
  </div>

  <div class="workshop-admin-view-wrapper__download-box" *ngIf="shouldShowChecklistDownloadView">
    <span class="workshop-admin-view-wrapper__download-box__file_name">{{checkListFile?.name}}</span>
    <div class="workshop-admin-view-wrapper__download-box__action">
      <button [hidden]="true"
              class="wavw__download-box__action">
        <img src="/assets/images/download.svg">
      </button>
      <button (click)="deleteFile('checklist')"
              class="wavw__download-box__action">
        <img src="/assets/images/red_delete_icon.svg">
      </button>
    </div>
  </div>

  <div class="workshop-admin-view-wrapper-upload" *ngIf="isWorkshop">
    <label class="workshop-admin-view-wrapper-upload__title">Upload Attendees List</label>
    <div class="workshop-admin-view-wrapper-upload__box" fileDragDrop (filesChangeEmiter)="onFileDroppedAttendees($event)">
      <input type="file" #fileDropRef1 id="fileDropRef1" multiple (change)="attendeesFileBrowseHandler($event)" />
      <div class="workshop-admin-view-wrapper-upload__box--icon">
        <img src="assets/images/upload_file.svg">
      </div>
      <div class="workshop-admin-view-wrapper-upload__box--text">
        <span class="workshop-admin-view-wrapper-upload__box--text--1">Drag & drop your files here or</span>
        <span class="workshop-admin-view-wrapper-upload__box--text--2">
        <label class="workshop-admin-view-wrapper-upload__box--text--2" for="fileDropRef1">choose files</label>
      </span>
      </div>
      <label class="workshop-admin-view-wrapper-upload__max-size">10 MB max file size</label>
    </div>
  </div>

  <div class="workshop-admin-view-wrapper__download-box" *ngIf="shouldShowAttendeesDownloadView">
    <span class="workshop-admin-view-wrapper__download-box__file_name">{{attendeeFile?.name}}</span>
    <div class="workshop-admin-view-wrapper__download-box__action">
      <button [hidden]="true"
              class="wavw__download-box__action">
        <img src="/assets/images/download.svg">
      </button>
      <button (click)="deleteFile('attendees')"
              class="wavw__download-box__action">
        <img src="/assets/images/red_delete_icon.svg">
      </button>
    </div>
  </div>


  <div class="view-status-wrapper-bottom">
    <button class="view-status-wrapper-bottom__cancel" (click)="onCancelRequest('cancel')" mat-button>Cancel</button>
    <button class="view-status-wrapper-bottom__schedule" (click)="onClickScheduleRequest(workshopSiteVisit.status)"
            mat-button>{{accept}}</button>
  </div>

</div>
