<mat-nav-list class="mat-nav">
  <div *ngFor="let menuItem of menus" class="list-of-item">
    <span *ngIf="menuItem.children && menuItem.children.length > 0">
      <mat-expansion-panel [class.mat-elevation-z0]="true">
        <mat-expansion-panel-header>
          {{ menuItem.name }}
        </mat-expansion-panel-header>
        <mat-nav-list *ngFor="let child of menuItem.children">
          <a mat-list-item [routerLink]="[menuItem.link]" routerLinkActive="active" (click)="route(child)">
            <!-- <mat-icon *ngIf="child.hasIcon">{{ child.icon }}</mat-icon> -->
            <img [src]="child.icon" alt="">
            <div>{{ child.name }}</div>
          </a>
        </mat-nav-list>
      </mat-expansion-panel>
    </span>
    <span *ngIf="!menuItem.children || menuItem.children.length === 0">
      <mat-list-item [routerLink]="[menuItem.link]" routerLinkActive="active">
        <!-- <mat-icon *ngIf="menuItem.hasIcon">{{ menuItem.icon }}</mat-icon> -->
        <img [src]="menuItem.icon" alt="">
        <div>{{ menuItem.name }}</div>
      </mat-list-item>
    </span>
  </div>
</mat-nav-list>