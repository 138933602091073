import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {WorkshopService} from '../../services/workshop/workshop.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WorkshopSiteVisitModel} from '../../../model/workshop/workshopSiteVisitModel';

@Component({
  selector: 'app-worksho-site-visit-status',
  templateUrl: './workshop-site-visit-request-status.component.html',
  styleUrls: ['./workshop-site-visit-request-status.component.scss']
})
export class WorkshopSiteVisitRequestStatusComponent {
  siteVisitWorkshop: WorkshopSiteVisitModel;
  siteVisitWorkshopData:WorkshopSiteVisitModel;
  workShopData:any;
  from: string;

  constructor(public dialogRef: MatDialogRef<WorkshopSiteVisitRequestStatusComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar) {
      this.from = data.from;
      this.siteVisitWorkshop = data.response
      this.siteVisitWorkshopData=data.siteVisitSch
      this.workShopData=data.workshop
  }

  onClickViewStatus = () => {
    console.log('On Click on view button',this.siteVisitWorkshopData,this.workShopData);
    const response = {
      status: 'view',
      from: this.from,
      workshopSiteVisit: this.siteVisitWorkshop,
      siteVisitWorkshopData:this.siteVisitWorkshopData,
      workShopData:this.workShopData
    }
    this.onClickedClose(response);
  }

  onClickedClose = (event: any) => {
    this.dialogRef.close(event);
  }
}
