export class App {
  private _id: string

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}

export class Project {
  private _id: string


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}

export class ScheduleWorkshop {
  app: App;
  project: Project;
  name: string;
  description: string;
  start_date: string;
  endDate: string;
  confirmDate: string;
  requested_at: string;
  client_representative_email: string;
  client_representative_phone: string;
  status: string;
  attendees: string[] = [];


  constructor(app: App,
              project: Project,
              name: string,
              description: string,
              start_date: string,
              client_representative_email: string,
              client_representative_phone: string,
              status: string,
              attendees: string[]) {
    this.app = app;
    this.project = project;
    this.name = name;
    this.description = description;
    this.start_date = start_date;
    this.client_representative_email = client_representative_email;
    this.client_representative_phone = client_representative_phone;
    this.status = status;
    this.attendees = attendees;
  }
}
