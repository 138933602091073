<div class="blank_workshop_wrapper">
  <!-- header -->
  <div class="blank_workshop_wrapper__header">
    <label class="blank_workshop_wrapper__header--title">Schedule workshop/Site visit</label>
    <div class="blank_workshop_wrapper__header--current-date">
      <label class="blank_workshop_wrapper__header--current-date--title">Today</label>
      <label class="blank_workshop_wrapper__header--current-date--value">{{today}}</label>
    </div>
  </div>
  <mat-dialog-content>
    <div class="blank_workshop_wrapper__body">
      <label class="blank_workshop_wrapper__body--text">No workshop/Site visit has been scheduled. Click Schedule
        Now!</label>
      <div class="blank_workshop_wrapper__body--gif">
        <img src="/assets/images/blank_schedule.gif" alt="There is no scheduled Item available">
      </div>
    </div>
  </mat-dialog-content>

  <div class="workshop-container__bottom">
    <mat-dialog-actions>
      <button class="workshop-container__bottom--schedule" (click)="onClickSchedule()" mat-button>Schedule</button>
    </mat-dialog-actions>
  </div>

</div>
