import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  width: any
  @Input() filterNames: any = [];
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>()
  isSelected: boolean = false;
  filterSelected: any;
  selected: boolean = true;

  ngOnInit(): void {
    this.selected = true;
  }

  onChangeFilter = (event: any, name: any) => {
    this.isSelected = event.source.checked;
    this.selected = false;
    this.filterSelected = name.toUpperCase();
    console.log(this.filterSelected)
    this.onFilterChange.emit(this.filterSelected);
  }
}
