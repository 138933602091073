<div class="reset-confirm-container">
    <div class="reset-confirm-heading-container">
        <div class="reset-confirm-heading">{{titleType}}</div>
        <div class="reset-confirm-heading-closing">
            <img (click)="closeDialog()" src="/assets/images/close.svg" />
        </div>
    </div>
    <div class="reset-confirm-main-container">
        <ng-container *ngIf="userRole==='Client'">
            <ng-container *ngIf="typeChange==='BuiltUp'">
                <div class="reset-confirm-main">
                    To update details like <span class="highlight-error">built up Area, additional fees and admin approval are needed</span>. If you agree, Type <span class="highlight-success">'confirm'</span>. After completion, your request will be sent to the admin, and your panel Criterion List, Criterion Details and Feedback will be tempararily frozen.
                </div>
            </ng-container>
            <ng-container *ngIf="typeChange==='Other'">
                <div class="reset-confirm-main">
                    After saving your edits <span class="highlight-error">all Criteria, Building details, and Compliance Documents will be reset</span>. You will be then required to fill Project Details Basic information as well. If you agree with this action, Kindly type <span class="highlight-success">'confirm'</span> to proceed
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="userRole==='admin' || userRole==='super-admin'">
            <div class="reset-confirm-main">
                The Client want to update information, like built up area, which requires additional fees. Once the fees are recieved, grant the client to access Panel
            </div>
        </ng-container>
    </div>
    <div *ngIf="userRole==='Client'" class="reset-confirm-input-container">
        <label for="confirm">Type confirm<span class="highlight-error">*</span></label>
        <mat-form-field>
            <input type="text" matInput [(ngModel)]="isConfirm" placeholder="Type here..." />
        </mat-form-field>
    </div>
    <div class="reset-confirm-btn-container">
        <button class="btn-custom btn-secondary-custom" (click)="closeDialog()">Cancel</button>
        <button class="btn-custom btn-primary-custom" *ngIf="userRole==='Client' && typeChange==='Other'" (click)="onConfirm()" [disabled]="isConfirm!=='confirm'">Confirm</button>
        <button class="btn-custom btn-primary-custom" *ngIf="userRole==='Client' && typeChange==='BuiltUp'"  (click)="onReset()" [disabled]="isConfirm!=='confirm'">Send request</button>
        <!-- <button class="btn-custom btn-secondary-custom" (click)="onReject()" *ngIf="userRole==='Admin'">Reject</button> -->
        <button class="btn-custom btn-primary-custom" (click)="onAccept()" *ngIf="userRole==='admin' || userRole==='super-admin'" [disabled]="this.projectDetails.project_fee_status">Accept</button>
    </div>
</div>