import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(value: number): string {
    // Convert the number to a string
    const stringValue = value.toString();
    
    // Split the string into groups of two digits from right to left
    const groups = stringValue.match(/\d{1,2}(?=(\d{3})+(?!\d))/g)

    // Join the groups with commas and reverse the order
    const formattedValue = groups ? groups.reverse().join(',') : stringValue;

    return formattedValue;
  }
}
