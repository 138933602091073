<div class="gauge-container">
    <div class="header">
      <div class="criteria">{{criteriaNo}}</div>
      <div class="title">{{title}}</div>
    </div>
    <ngx-gauge
      [type]="gaugeType"
      [value]="gaugeValue"
      [label]="gaugeLabel"
      [append]="gaugeAppendText"
      [size]="size"
      [thick]="thick"
      [min]="min"
      [max]="max"
      [foregroundColor]="foregroundColor"
      [backgroundColor]="backgroundColor"
      [markers]="markerConfig"
    ></ngx-gauge>
  </div>
