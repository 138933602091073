<div class="meeting-container-wrapper__row">
  <div class="meeting-container-wrapper__row--header">
    <div class="meeting-container-wrapper__row--header__left">
      <div class="meeting-container-wrapper__row--header__name">
        <label class="meeting-container-wrapper__row--header__name--text">{{workshopRow.name}}</label>
      </div>
      <div class="meeting-container-wrapper__row--header--subtitle">
        <label class="meeting-container-wrapper__row--header--subtitle--text">{{workshopRow.description}}</label>
      </div>
    </div>
    <div class="meeting-container-wrapper__row--header__right">
      <label class="meeting-container-wrapper__row--header__right--workshop-status">{{workshopRow.status}}</label>
    </div>
  </div>


  <!-- workshop week -->
  <div class="workshop-container-wrapper__row__detail_row--workshop-schedule">
    <img class="workshop-container-wrapper__row__detail_row--workshop-schedule__icon" src="assets/images/clock.svg"/>
    <label class="workshop-container-wrapper__row__detail_row--workshop-schedule__text">{{workshopWeek}}</label>
  </div>

  <!-- calendar view -->
  <app-griha-calendar *ngIf="workshopRow.status === 'PROPOSED'"
                      [selectedDateValue]="adminProposeWeek"
                      (onSelectedDate)="onSelectedWeek($event)">

  </app-griha-calendar>

  <!-- Attendees section -->
  <div>
    <app-meeting-attendees [attendees]="workshopRow.attendees"></app-meeting-attendees>
  </div>

  <!-- site visit week -->
  <div class="workshop-container-wrapper__row__detail_row--site-visit-schedule">
    <img class="workshop-container-wrapper__row__detail_row--site-visit-schedule__icon" src="assets/images/clock.svg"/>
    <label class="workshop-container-wrapper__row__detail_row--site-visit-schedule__text">{{siteVisitWeek}}</label>
  </div>
  <div class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container">
    <button class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__cancel" (click)="onNegativeButtonClicked(negativeAction)">{{negativeAction}}</button>
    <button
      class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__rescheduled" (click)="onPositiveButtonClicked(positiveAction)">{{positiveAction}}</button>
  </div>

</div>


