import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectedDate} from '../../../calendar/griha-calendar/griha-calendar.component';

@Component({
  selector: 'app-meeting-attendees',
  templateUrl: './meeting-attendees.component.html',
  styleUrls: ['./meeting-attendees.component.scss']
})
export class MeetingAttendeesComponent {
  @Input() attendees : string[] = [];
  @Input() isRemoval: boolean = false;
  @Output() onDeletedAttendees = new EventEmitter<string>();

  constructor() {
  }

  removeAttendees = (name: string) => {
    this.onDeletedAttendees.emit(name);
  }


}
