import { Component } from '@angular/core';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-old-panel',
  templateUrl: './old-panel.component.html',
  styleUrls: ['./old-panel.component.scss']
})
export class OldPanelComponent {

  constructor(private dataService : DataSharingService) {
    if(dataService.clientRole == 'Admin' || dataService.clientRole == 'Super-Admin'){
      window.location.href = environment.old_griha_base + '/Admin/Index'
      // window.location.href = environment.griha_url
      // console.log('true',dataService.clientRole)
    }else{
      // console.log('false', dataService.clientRole)
      window.location.href = environment.old_griha_base + '/Project/ProjectList'
      // window.location.href = environment.griha_url
      
    }
    // console.log(environment.old_griha_base + '/' + dataService.clientRole)
    // http://124.123.18.67:55443/grihaintegration
    // http://124.123.18.67:55443/grihaintegration/Project/ProjectList
  }
}
