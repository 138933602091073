import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {ResponsePacket} from '../../model/ResponsePacket';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {NotificationModel} from '../../model/notification/notification.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private params: any;


  constructor(private http: HttpClient, private router: Router) {
    this.params = new HttpParams();
  }

  private NOTIFICATION_URL = `/usernotification/list?status=NEW`;
  // private USER_NOTIFICATION_URL = `/usernotification/list?user={}`;
  private UPDATE_NOTIFICATION_STATUS = `/usernotification/#`

  fetchNotifications = (): Observable<ResponsePacket<NotificationModel[]>> => {
    return this.http.get<ResponsePacket<NotificationModel[]>>(this.NOTIFICATION_URL)
      .pipe(
        catchError(this.handleError)
      );
  }

  fetchUserNotifications = (userId: string): Observable<ResponsePacket<NotificationModel[]>> => {
    return this.http.get<ResponsePacket<NotificationModel[]>>(this.NOTIFICATION_URL)
      .pipe(
        catchError(this.handleError)
      );
  }

  readNotification = (status: string, id: string) => {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.patch<void>(this.UPDATE_NOTIFICATION_STATUS.replace("#", id),
      JSON.stringify({'status': status}), {headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError = (error: { error: { message: any; }; status: any; message: any; }) => {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
