import {Component, Input, Output, EventEmitter} from '@angular/core';
import {GrihaNotification} from '../model/griha-notification';
import {NotificationModel} from '../../model/notification/notification.model';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() notifications: Array<NotificationModel> = [];
  @Output() onClickNotificationRow = new EventEmitter<NotificationModel>();
  @Output() onReadAllNotification = new EventEmitter<boolean>();
  @Output() clickMarkAsRead = new EventEmitter<NotificationModel>();
  @Output() clickMarkAsNew = new EventEmitter<NotificationModel>();

  constructor() {
  }

  onClickMarAllRead = () => {
    this.notifications = [];
    this.onReadAllNotification.emit(true);
  }

  onClickNotification = (notification: NotificationModel) => {
    this.onClickNotificationRow.emit(notification)
  }

  isNotRead = (notification: NotificationModel) => {
      return notification.status === 'New';
  }

  onClickMarkAsRead = (notification: NotificationModel) => {
    this.clickMarkAsRead.emit(notification);
  }

  onClickMarkAsNew=(notification:NotificationModel)=>{
    this.clickMarkAsNew.emit(notification);
  }
}
