import {Component, Output, EventEmitter} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-blank-workshop-view',
  templateUrl: './blank-workshop-view.component.html',
  styleUrls: ['./blank-workshop-view.component.scss']
})
export class BlankWorkshopViewComponent {
  today: string = '';
  @Output() onClickBlankScheduleBtn = new EventEmitter<string>()

  constructor() {
    this.today = moment().format("MMM D, YYYY");
  }

  onClickSchedule = () => {
  this.onClickBlankScheduleBtn.emit('blnk_schedule')
  }
}
