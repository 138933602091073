import {Component, Inject, Optional} from '@angular/core';
import {WorkshopSiteVisitModel} from '../../model/workshop/workshopSiteVisitModel';
import {SelectedDate} from '../../shared/calendar/griha-calendar/griha-calendar.component';
import {UserData} from '../../model/UserData';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppUtils} from '../../shared/apputils/app.utils';
import {ProjectService} from '../../shared/services/project/project.service';
import {Client, Coordinator, UploadDocModel} from '../../model/upload_doc.model';
import {App} from '../../model/workshop.model';
import {AppConstants} from '../../shared/apputils/appConstant';
import {ProjectData} from '../../model/ProjectData';
import {forkJoin} from 'rxjs';
import {SiteVisitService} from '../../shared/services/site-visit.service';
import {WorkshopService} from '../../shared/services/workshop/workshop.service';
import {WorkshopStatus} from '../../model/workshop.status.enum';
import { DataSharingService } from 'src/app/shared/services/data-sharing.service';

@Component({
  selector: 'app-workshop-site-visit',
  templateUrl: './workshop-site-visit.component.html',
  styleUrls: ['./workshop-site-visit.component.scss']
})
export class WorkshopSiteVisitComponent {
  workshopSiteVisit: WorkshopSiteVisitModel;
  workshopWeek: string;
  remainingDays: number;
  selectedDateValue: SelectedDate;
  selectedCoord: string;
  selectedAddCoord: string;
  coordinators: UserData[] = [];
  selectedDate: string
  isWorkshop: boolean = false;
  accept = 'Done';
  isDisable = true;
  isFeePaid: boolean = false;
  type: string = 'Workshop'
  subTitle: string = 'Assign to you';
  clientStatus: string = 'Requested by client';
  confirmedDate: any;
  from: string
  checkListFile: any;
  attendeeFile: any;
  siteVisitType: string;
  projectCode: string;
  projectData: ProjectData
  shouldShowAttendeesDownloadView: boolean = false;
  shouldShowChecklistDownloadView: boolean = false;
  workspSiteVisitRes: any;
  workshopRes: any;
  projectDetails:any;
  appId:string;

  constructor(public dialogRef: MatDialogRef<WorkshopSiteVisitComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private projectService: ProjectService,
              private siteVisitService: SiteVisitService,
              private workshopService: WorkshopService,
              private dataSharingService:DataSharingService) {
    this.workshopSiteVisit = data.wokshop;
    this.from = data.which;
    this.type = data.which === 'siteVisit' ? 'SiteVisit' : 'Workshop';
    this.isWorkshop = this.type === 'Workshop';
    console.log(`Data ${this.workshopSiteVisit}`);
    this.projectData = data.project;
    this.projectCode = data.prCode;
    this.siteVisitType = this.from === 'siteVisit' ? this.workshopSiteVisit.site_visit_type : 'Optional';

    if (this.type === 'Workshop') {
      this.workshopRes = AppUtils.refreshUi(this.workshopSiteVisit);
      console.log(`Workshop status :: ${this.workshopRes.status}`);
    }

    if (this.type === 'SiteVisit' || (data.mandatorySiteVisit || data.siteVisitSch)) {
      const sitVisitObj = this.siteVisitType === 'MANDATORY' ? data.mandatorySiteVisit : data.siteVisitSch;
      this.workspSiteVisitRes = AppUtils.refreshUi(sitVisitObj);
    }

  }

  ngOnInit(){
    this.dataSharingService.projectDetails$.subscribe((data)=>{
      this.projectDetails=data;
      this.appId=this.projectDetails.app._id;
    })
  }


  onClickScheduleRequest = (event: any) => {
    const app = new App();
    app.id = this.appId;

    const coordinator = new Coordinator();
    coordinator.id = this.projectData.coordinator._id;

    const client = new Client();
    client.id = this.projectData.client._id;
    if (this.checkListFile && this.attendeeFile) {
      const uploadReq = new UploadDocModel(app, this.projectData.name, this.projectData.description, this.projectData.type
        , coordinator, client, this.projectData.state, this.projectData.city, this.projectData.organization,
        this.projectData.site_area, this.projectData.built_up_area);

      console.log(`Checklist:: ${this.checkListFile}`);

      const checkList$ = this.projectService.uploadCheckList(uploadReq, this.projectCode, this.checkListFile);
      console.log(`Attendees:: ${this.attendeeFile}`)
      const attendeesList$ = this.projectService.uploadAttendeesList(uploadReq, this.projectCode, this.attendeeFile);

      forkJoin([checkList$, attendeesList$]).subscribe({
        next: response => {
          const uploadCheckListS3$ = this.projectService.uploadFileToS3(response[0].data.upload_url, this.checkListFile);
          const attendeesCheckListS3$ = this.projectService.uploadFileToS3(response[1].data.upload_url, this.attendeeFile);
          console.log(`Test response:: ${response}`);
          forkJoin([uploadCheckListS3$, attendeesCheckListS3$]).subscribe({
            next: result => {
              console.log(`S3 call started result:: ${result}`);
              const service = this.isWorkshop ? this.workshopService : this.siteVisitService;
              this.updateSiteVisitWorkshopStatus(service, this.workshopSiteVisit._id, WorkshopStatus.WORKSHOP_STATUS_DONE);
            },
            error: err => {
              console.error(`S3 call end with :: ${err}`);
              this.dialogRef.close({error: true, res: err});
            }
          });
        },
        error: err => {
          console.error(`call end with :: ${err}`);
          this.dialogRef.close({error: true, res: err});
        }
      });
    } else {
      this.snackBar.open('Please upload both file.', 'error', {
        duration: 5 * 1000,
        panelClass: ['error'],
      });
    }
  }

  onCancelRequest = (event: string) => {
    this.dialogRef.close({error: true, res: 'cancel'});
  }

  onFileDroppedCheckList = (event: any) => {
    console.log(`FileChanges ${event}`);
    this.prepareFilesList(event.target.files, 'checklist');
  }

  onFileDroppedAttendees = (event: any) => {
    console.log(`FileChanges ${event}`);
    this.prepareFilesList(event.target.files, 'attendees');
  }

  attendeesFileBrowseHandler = (event : any) => {
    console.log(`fileBrowseHandler ${event}`);
    this.prepareFilesList(event.target.files, 'attendees');
  }

  checkListFileBrowseHandler = (event : any) => {
    console.log(`fileBrowseHandler ${event}`);
    this.prepareFilesList(event.target.files, 'checklist');
  }

  prepareFilesList(files: Array<any>, from: string) {
    for (const item of files) {
      item.progress = 0;
      if (from === 'checklist') {
        this.checkListFile = files[0];
        this.shouldShowChecklistDownloadView = true
      } else {
        this.attendeeFile = files[0];
        this.shouldShowAttendeesDownloadView = true;
      }
    }
  }

  deleteFile = (from: string) => {
    if (from === 'checklist') {
      this.shouldShowChecklistDownloadView = false;
      this.checkListFile = undefined;
    } else {
      this.shouldShowAttendeesDownloadView = false
      this.attendeeFile = undefined;
    }
  }

  private updateSiteVisitWorkshopStatus = (service: any, id: string, status: string) => {
    service.updateStatus(id, status)
      .subscribe({
        next: (res: any) => {
          console.log(`Updated  ${res}`);
          this.snackBar.open('Workshop has been done successfully.', 'info', {
            duration: 5 * 1000,
            panelClass: ['info'],
          });
          this.dialogRef.close({error: false, statusVal: status});
        }, error: (err: any) => {
          console.error(`Issue in  ${err}`);
          this.snackBar.open(err, 'error', {
            duration: 5 * 1000,
            panelClass: ['error'],
          });
          this.dialogRef.close({error: true, res: err});
        }
      });
  }
}
