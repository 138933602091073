import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataSharingService } from '../shared/services/data-sharing.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {
  clientRole:string;
  constructor(private dataSharingService:DataSharingService){

  }
  ngOnInit(){
    this.clientRole=this.dataSharingService.clientRole;
    this.clientRole=this.clientRole.toLowerCase();
  }
  redirect(){
    window.location.href=`${environment.svagriha_url}/${this.clientRole}`;
  }
}
