import {Component, Output, EventEmitter, Input} from '@angular/core';
import {UserService} from '../../../admin/user/user.service';
import {DataSharingService} from '../../services/data-sharing.service';
import { ProjectService } from '../../services/project/project.service';
import { variableConstantFile } from 'src/assets/config/variableConstantFile';
import { ExtendRequestComponent } from '../../extend-request/extend-request.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-griha-project-detail-header',
  templateUrl: './griha-project-detail-header.component.html',
  styleUrls: ['./griha-project-detail-header.component.scss']
})
export class GrihaProjectDetailHeaderComponent {
  selectedValue: string = 'projectDetail';
  @Output() onDetailChanged = new EventEmitter<string>();
  @Output() onClickWorkshop = new EventEmitter<string>();
  @Output() onClickAssignUser = new EventEmitter<string>();
  @Output() updateProject=new EventEmitter <null>();
  @Input() isCoordinator: boolean = false;
  @Input() projectDetails:any;
  @Input() userRole:any;
  result: any;
  private holidayList: [] = [];
  variableConstantFile:any;
  criteriaEvaluators:any;
  IsEvaluatorsNotAssigned:boolean=false;

  constructor(private dataSharingService: DataSharingService,
    private projectService: ProjectService,private dialogBox:MatDialog) {
      this.variableConstantFile=variableConstantFile;
    this.getHoliday();
  }

  ngOnInit(){
    this.criteriaData();
    console.log(this.projectDetails);
    this.dataSharingService.holidayList=this.holidayList;
  }
  
  getHoliday(){
    this.projectService.getHolidayList().subscribe({
      next: (res) => {
        this.result = res;
        if (this.result.data) {
          this.holidayList = this.result.data;
          this.dataSharingService.holidayList=this.holidayList;
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  criteriaData(){
    this.projectService.getCriteriaData(this.projectDetails._id).subscribe({
      next:res=>{
        this.result=res;
        this.criteriaEvaluators=this.result.data;
        if(this.criteriaEvaluators){
          for(let evaluator of this.criteriaEvaluators){
            console.log(evaluator.evaluator);
            if(!evaluator.evaluator){
              this.IsEvaluatorsNotAssigned=true;
            }
          }
        }
      },
      error:err=>{
        console.error(err);
      }
    })
  }

  onWorkshopSchedule = (type : string) => {
    this.onClickWorkshop.emit(type)
  }

  assignUser = () => {
    this.onClickAssignUser.emit('assignUser');
  }

  onSelectionChange = (value: string) => {
    this.onDetailChanged.emit(value);
  }

  viewRequest(){
    const data={
      projectDetails:this.projectDetails,
      isUser:this.userRole
    }
    const dialogRef=this.dialogBox.open(ExtendRequestComponent,{data});
    dialogRef.afterClosed().subscribe((res)=>{
      console.log(res);
      if(res.update){
        this.updateProject.emit();
      }
    });
  }

}
