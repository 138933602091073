import {App, Project} from './workshop.model';

export class SiteVisitRequestModel {
  app: App;
  project: Project;
  name: string;
  description: string;
  start_date: string;
  site_visit_type: string;
  client_representative_email: string;
  client_representative_phone: string;
  status: string


  constructor(app: App,
              project: Project,
              name: string,
              description: string,
              start_date: string,
              site_visit_type: string,
              client_representative_email: string,
              client_representative_phone: string) {
    this.app = app;
    this.project = project;
    this.name = name;
    this.description = description;
    this.start_date = start_date;
    this.site_visit_type = site_visit_type;
    this.client_representative_email = client_representative_email;
    this.client_representative_phone = client_representative_phone;
  }


}
