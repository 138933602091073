import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttachmentComponent } from 'src/app/client/attachment/attachment.component';
import { ProjectAndPersonalDetailService } from '../../services/client-detail/project-and-personal-detail.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CapitalizePipe } from '../../pipes/capitalize.pipe';

@Component({
  selector: 'app-reset-confirm',
  templateUrl: './reset-confirm.component.html',
  styleUrls: ['./reset-confirm.component.scss'],
})
export class ResetConfirmComponent {
  userRole: string;
  typeChange: string;
  formData: any;
  titleType: string = '';
  isConfirm: string = '';
  projectDetails: any;
  result: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AttachmentComponent>,
    private projectAndPersonalDetailService: ProjectAndPersonalDetailService,
    private snackBars: MatSnackBar
  ) {
    this.userRole = this.data.userRole;
    this.typeChange = this.data.typeChange;
    this.formData = this.data.formValue;
    this.projectDetails = this.data.projectDetails;
    this.titleType =
      this.userRole === 'Client'
        ? 'Are you sure you want to save changes?'
        : 'Request for edit';
    console.log(this.userRole);
    console.log(this.projectDetails);
    if (this.projectDetails._id) {
      this.projectDetails.id = this.projectDetails._id;
    }
  }

  ngOnInit() {
    const loginRole = sessionStorage.getItem('loginRole');
    if (
      loginRole &&
      (this.userRole === 'Admin' || this.userRole === 'Super-Admin')
    ) {
      this.userRole = loginRole;
      this.userRole =
        this.userRole.charAt(0).toUpperCase() + this.userRole.slice(1);
      this.titleType =
        this.userRole === 'Client'
          ? 'Are you sure you want to save changes?'
          : 'Request for edit';
    }
  }

  onConfirm() {
    const data = {
      project_fee_status: true,
      ...this.formData,
    };
    const message = 'Project is reset';
    this.updateProject(data, message);
  }

  onReset() {
    const data = {
      project_fee_status: false,
      ...this.formData,
    };
    const message = 'Request sent to admin for pending fee';
    this.updateProject(data, message);
  }

  // onReject(){
  //   const data={
  //     project_fee_status:true
  //   }
  //   console.log(data);
  // }

  onAccept() {
    const data = {
      project_fee_status: true,
    };
    const message = 'Project fee is accepted';
    this.updateProject(data, message);
  }

  updateProject(formValue: any, message: string) {
    this.projectAndPersonalDetailService
      .deleteBuildings(this.projectDetails.id)
      .subscribe({
        next: (res) => {
          this.projectAndPersonalDetailService
            .resetCalculator(this.projectDetails.id)
            .subscribe({
              next: (res) => {
                this.projectAndPersonalDetailService
                  .updateProject(formValue, this.projectDetails.project_code)
                  .subscribe({
                    next: (response) => {
                      // console.log('Res', response);
                      this.result = response;
                      this.snackBars.open(message, 'Ok', { duration: 3000 });
                      this.dialogRef.close({ edit: true });
                    },
                    error: (err) => {
                      console.log('error', err);
                    },
                  });
              },
              error: (err) => {
                console.log('error', err);
              },
            });
        },
        error: (err) => {
          console.log('error', err);
        },
      });
  }

  closeDialog() {
    this.dialogRef.close({ edit: false });
  }
}
