<div class="holiday_upload--wrapper">

  <div class="holiday_upload--wrapper__heading">
    Holiday Calendar
  </div>

  <mat-button-toggle-group class="holiday_upload--wrapper__tabs-group" [value]="groupSelected" (change)="onToggleChange($event)">
    <mat-button-toggle class="holiday_upload--wrapper__tabs-buttons" value="upload">Upload</mat-button-toggle>
    <mat-button-toggle class="holiday_upload--wrapper__tabs-buttons" value="uploaded_holiday">Uploaded Holiday</mat-button-toggle>
  </mat-button-toggle-group>

  <ng-container *ngIf="groupSelected==='upload'">
    <div class="holiday_upload--wrapper__upload-section">
      <div class="holiday_upload--wrapper__upload-section--box">
        <!-- <label class="holiday_upload--wrapper__upload-section--box_title">Upload holiday List</label> -->
        <!-- <div class="holiday_upload--wrapper__upload-section--box_drag-area" fileDragDrop (fileDropped)="onFileDroppedHolidayList($event)">
          <input type="file" #fileDropRef id="fileDropRef" 
          accept=".csv" (change)="holidaysFileBrowseHandler($event)" />
          <div class="holiday_upload--wrapper__upload-section--box_drag-area--icon">
            <img src="assets/images/upload_file.svg">
          </div>
  
          <div class="holiday_upload--wrapper__upload-section--box--text">
            <span class="holiday_upload--wrapper__upload-section--box--text--1">Drag & drop your files here or</span>
            <span class="holiday_upload--wrapper__upload-section--box--text--2">
            <label class="holiday_upload--wrapper__upload-section--box--text--2" for="fileDropRef">choose files</label>
          </span>
          </div>
          <label class="holiday_upload--wrapper__upload-section--box_drag-area__max-size">10 MB max file size</label>
        </div> -->
        <div class="input-container">
          <label class="fileLabel" for="fileInput">
            <div>
              <span class="fileLabel-color-blue">Click to Upload </span>
              <span class="fileLabel-color-black">or drag and drop</span>
            </div>
            <div class="fileLabel-color-grey">
              Max. File Size : 15MB
            </div>
          </label>
          <input
            type="file"
            multiple
            id="fileInput"
            (change)="holidaysFileBrowseHandler($event)"
            accept=".csv"
            formControlName="documents"
          />
        </div>
      </div>
  
      <div class="workshop-admin-view-wrapper__download-box" *ngIf="shouldShowChecklistDownloadView">
        <span class="workshop-admin-view-wrapper__download-box__file_name">{{holidayFiles?.name}}</span>
        <div class="workshop-admin-view-wrapper__download-box__action">
          <button [hidden]="true"
                  class="wavw__download-box__action">
            <img src="/assets/images/download.svg">
          </button>
          <button (click)="deleteFile()"
                  class="wavw__download-box__action">
            <img src="/assets/images/red_delete_icon.svg">
          </button>
        </div>
      </div>
    </div>
  
    <div class="view-status-wrapper-bottom">
      <button class="view-status-wrapper-bottom__cancel" (click)="onCancelRequest()" mat-button>Cancel</button>
      <button class="view-status-wrapper-bottom__schedule" (click)="onClickUploadRequest()">Upload Holiday</button>
    </div>
  </ng-container>

  <ng-container *ngIf="groupSelected==='uploaded_holiday'">
    <div class="holiday_upload--wrapper__list">
      <div *ngFor="let list of holidayList;index as i" class="holiday_upload--wrapper__list-items">
        <span>{{i+1}} : </span>
        <span>{{list | dateFormatWtTime}}</span>
      </div>
    </div>
  </ng-container>
</div>
