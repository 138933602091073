import {Component, Inject, Optional} from '@angular/core';
import {ProjectService} from '../../services/project/project.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-holiday-upload',
  templateUrl: './holiday-upload.component.html',
  styleUrls: ['./holiday-upload.component.scss']
})
export class HolidayUploadComponent {

  holidayFiles: any;
  shouldShowChecklistDownloadView: boolean = false
  groupSelected:string='upload';
  holidayList:[]=[];

  constructor(private projectService: ProjectService,
              public dialogRef: MatDialogRef<HolidayUploadComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar) {
                this.getHolidayData()
  }

  onClickUploadRequest = () => {
    if (this.holidayFiles) {
      console.log(`HolidayFiles:: ${this.holidayFiles}`);
      const allowedExtensions = ['.csv'];

      const invalidFiles=this.holidayFiles.name.split('.').pop()?.toLowerCase();
        console.log(invalidFiles);
      if (!allowedExtensions.includes(`.${invalidFiles}`)) {
        this.snackBar.open('Please select only .csv files', 'Ok',{duration:3000});
        this.holidayFiles = undefined;
        this.shouldShowChecklistDownloadView=false;
        return;
      }
      
      const result = confirm('Do you want to Import the File for Holiday?');
      if (result) {
          this.uploadFile(this.holidayFiles);
      }
    } else {
      this.snackBar.open('Please upload file.', 'error', {
        duration: 5 * 1000,
        panelClass: ['error'],
      });
    }
  }

  uploadFile(file: any) {
    this.projectService.importHoliday(file).subscribe({
      next: (res) => {
        this.snackBar.open('Holiday File is imported', 'Ok',{duration:3000});
        this.holidayFiles=undefined;
        this.shouldShowChecklistDownloadView=false;
      },
      error: (err) => {
        console.error(err);
        this.snackBar.open(
          'Something went wrong! Check Date Format DD-MM-YYYY',
          'Ok',{duration:3000}
        );
      },
    });
  }

  onFileDroppedHolidayList = (event: any) => {
    this.holidayFiles=event.target.files[0]
    this.shouldShowChecklistDownloadView = true;
  }

  holidaysFileBrowseHandler = (event : any) => {
    this.holidayFiles=event.target.files[0]
    this.shouldShowChecklistDownloadView = true;
  }

  deleteFile(){
    this.holidayFiles = undefined;
    this.shouldShowChecklistDownloadView=false;
  }

  onCancelRequest(){
    this.dialogRef.close();
  }

  onToggleChange(event:any){
    console.log(event);
    this.groupSelected=event.value;
  }

  getHolidayData(){
    this.projectService.getHolidayList().subscribe({
      next:res=>{
        const result:any=res;
        this.holidayList=result.data;
      }
    })
  }

}
