import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {WorkshopSiteVisitModel} from '../../../../../model/workshop/workshopSiteVisitModel';
import moment from 'moment';
import {SelectedDate} from '../../../../calendar/griha-calendar/griha-calendar.component';
import {WorkshopService} from '../../../../services/workshop/workshop.service';
import {WorkshopStatus} from '../../../../../model/workshop.status.enum';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-metting-row',
  templateUrl: './meeting-row.component.html',
  styleUrls: ['./meeting-row.component.scss']
})
export class MeetingRowComponent implements OnInit {
  @Input() workshopRow: WorkshopSiteVisitModel;
  @Output() onClickedCancel = new EventEmitter<string>();
  @Output() onClickedPositiveAction = new EventEmitter<string>();
  adminProposeWeek: SelectedDate;
  clientProposeWeek: SelectedDate;
  workshopWeek: string;
  siteVisitWeek: string = '(August 14 - August 18, 2023)';
  negativeAction: string = 'Cancel';
  positiveAction: string = 'Reschedule';

  constructor(private workshopService: WorkshopService, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    console.log(`Meeting Row in oninit ${this.workshopRow}`);
    this.enrichWorkshopRequest();
  }

  private enrichWorkshopRequest() {
    console.log(`Meeting Row ${this.workshopRow}`);
    if (this.workshopRow && this.workshopRow.selected_days && this.workshopRow.selected_days.length > 0) {
      const startDay = this.workshopRow.selected_days[0];
      const endDay = this.workshopRow.selected_days[4]
      const momentStartDate = moment(startDay, 'YYYY-MM-DD');
      const momentEndDate = moment(endDay, 'YYYY-MM-DD');
      this.adminProposeWeek = new SelectedDate();
      this.adminProposeWeek.selectedStartWeek = momentStartDate;
      this.adminProposeWeek.selectedEndWeek = momentEndDate;
      this.adminProposeWeek.selectedDate = `${momentStartDate} - ${momentEndDate}`;

      const startMonthAndDate = momentStartDate.format('MMMM DD');
      const endMonthAndDate = momentEndDate.format('MMMM DD');
      const year = momentStartDate.format('YYYY');
      this.workshopWeek = `(${startMonthAndDate} - ${endMonthAndDate}, ${year})`;
      if (this.workshopRow.status === 'PROPOSED') {
        this.positiveAction = 'Accept Proposal';
        this.adminProposeWeek.selectedDate = `Proposed date ${this.adminProposeWeek.selectedStartWeek}`;
      }
    } else {
      console.log(`There is no workshop start end date available for workshopId ${this.workshopRow}`)
    }
  }

  onNegativeButtonClicked = (action: string) => {
    this.onClickedCancel.emit(action);
  }

  onPositiveButtonClicked = (action: string) => {
    if (this.isAcceptProposedDate(action)) {
      this.workshopService.updateStatus(this.workshopRow._id, WorkshopStatus.WORKSHOP_STATUS_PENDING_CONFIRMATION,false)
        .subscribe({
          next: res => {
            console.log(`Updated workshop status ${res}`);
            this.onClickedPositiveAction.emit(action);
          },
          error: err => {
            console.error(`Issue in workshop status ${err}`)
          }
        });
    } else if (this.clientProposeWeek.selectedStartWeek.isAfter(this.adminProposeWeek.selectedEndWeek)) {
      this.adminProposeWeek = this.clientProposeWeek;
      const proposedDate = this.clientProposeWeek.selectedStartWeek.format('YYYY-MM-DD');
      this.workshopService.proposeStatus(this.workshopRow._id, proposedDate, "", "")
        .subscribe({
          next: response => {
            console.log(`New Proposed StartDate ${response.data.attendees[0]} and Enddate ${response.data.attendees[4]}`);
            this.onClickedPositiveAction.emit(action);
          },
          error: err => {}
        });
    } else {
      this.snackBar.open('Please select new date after selected week.', 'info', {
        duration: 5 * 1000,
        panelClass: ['info'],
      });
    }

  }

  onSelectedWeek = (selectedDate: SelectedDate) => {
    console.log(`selectedDates ${selectedDate}`);
    this.clientProposeWeek = selectedDate;
  }

  isAcceptProposedDate = (action: string) : boolean => {
    let isAccepted = false;
    if (!this.clientProposeWeek && action.includes('Proposal')) {
      isAccepted = true;
    } else if (this.clientProposeWeek.selectedStartWeek.isAfter(this.adminProposeWeek.selectedEndWeek)) {
      isAccepted = false;
    }
    return isAccepted;
  }
}
