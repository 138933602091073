import {Component, EventEmitter, Inject, Input, Optional, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WorkshopSiteVisitModel} from '../../../model/workshop/workshopSiteVisitModel';
import {SelectedDate} from '../../calendar/griha-calendar/griha-calendar.component';
import {WorkshopService} from '../../services/workshop/workshop.service';
import * as moment from 'moment';
import {AppUtils} from '../../apputils/app.utils';
import {WorkshopStatus} from '../../../model/workshop.status.enum';

@Component({
  selector: 'app-view-workshop-site-visit',
  templateUrl: './view-workshop-site-visit.component.html',
  styleUrls: ['./view-workshop-site-visit.component.scss']
})
export class ViewWorkshopSiteVisitComponent {
  isSiteVisit: boolean = false;
  workshopRes: any;
  workshop: WorkshopSiteVisitModel;
  siteVisit: WorkshopSiteVisitModel;
  siteVisitRes: any
  canReschedule: boolean = false
  siteVisitType: string = 'OPTIONAL';

  constructor(public dialogRef: MatDialogRef<ViewWorkshopSiteVisitComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar) {
    this.siteVisitType = data.site_visit_type;
    this.workshop = data.wokshop;
    // this.isSiteVisit = data.which == 'siteVisit' ;
    this.isSiteVisit=true;
    if (this.workshop) {
      this.workshopRes = this.refreshUi(this.workshop);
    }
    if (this.isSiteVisit && data.mandatorySiteVisit && this.siteVisitType === 'MANDATORY') {
      const sitVisitObj = data.mandatorySiteVisit;
      this.siteVisit = sitVisitObj;
      this.siteVisitRes = this.refreshUi(sitVisitObj);
    } else if (this.isSiteVisit && data.siteVisitSch) {
      const sitVisitObj = data.siteVisitSch;
      this.siteVisit = sitVisitObj;
      this.siteVisitRes = this.refreshUi(sitVisitObj);
    }
    console.log('Available WorkRes',this.workshopRes, 'SiteVisitRes:',this.siteVisitRes, 'Sitevisit :',this.siteVisit );
  }

  refreshUi = (siteVisitOrWorkshop: WorkshopSiteVisitModel) => {
    console.log(`Available project workshop ${siteVisitOrWorkshop}`);
    const result = AppUtils.buildDateRangeAndRemainingDays(siteVisitOrWorkshop);
    const confirmedProposedDate = AppUtils.buildProposalDateRangeAndRemainingDays(siteVisitOrWorkshop);
    const confirmedDate = AppUtils.buildConfirmDateRangeAndRemainingDays(siteVisitOrWorkshop);
    console.log(`Workshop status :: ${siteVisitOrWorkshop.status}`);
    let txtActionButton = '';
    let status = ''
    switch (siteVisitOrWorkshop.status) {
      case WorkshopStatus.WORKSHOP_STATUS_PROPOSED:
        txtActionButton = 'Accept';
        status = 'date proposed by admin';
        this.canReschedule = true;
        result.workshopWeek = confirmedProposedDate.workshopWeek;
        result.remainingDays = confirmedProposedDate.remainingDays;
        break;
      case WorkshopStatus.WORKSHOP_STATUS_FEE_APPROVAL_PENDING:
        txtActionButton = '';
        status = 'Pending fee approval'
        this.canReschedule = false;
        break;
      case WorkshopStatus.WORKSHOP_STATUS_PENDING_CONFIRMATION:
        txtActionButton = '';
        status = 'Pending Confirmation'
        this.canReschedule = false;
        if (siteVisitOrWorkshop.proposed_date) {
          result.workshopWeek = confirmedProposedDate.workshopWeek;
          result.remainingDays = confirmedProposedDate.remainingDays;
        }
        break;
      case WorkshopStatus.WORKSHOP_STATUS_CONFIRMED:
        txtActionButton = '';
        status = 'Confirmed';
        result.workshopWeek = confirmedDate.workshopWeek;
        result.remainingDays = confirmedDate.remainingDays;
        this.canReschedule = false;
        break;
      case WorkshopStatus.WORKSHOP_STATUS_DONE:
        txtActionButton = '';
        status = 'Done';
        result.workshopWeek = confirmedDate.workshopWeek;
        result.remainingDays = confirmedDate.remainingDays;
        this.canReschedule = false;
        break;
    }

    return {
      res: result,
      txtActionButton: txtActionButton,
      canReschedule: this.canReschedule,
      status: status
    };
  }

  onCancelRequest = (event: any) => {
    this.dialogRef.close({ eve: event});
  }

  onClickScheduleRequest = (fromWhich: string) => {
    const updateResponse = {
      eve: '',
      result: '',
      id: '',
      isSiteVisitAndWorkshopScheduled: false,
      from: ''
    }
    if (fromWhich === 'workshop') {
      updateResponse.eve = this.workshopRes.txtActionButton;
      updateResponse.id = this.workshop._id;
      updateResponse.result = this.workshopRes.txtActionButton;
      updateResponse.from = 'workshop';
    } else {
      updateResponse.eve = this.siteVisitRes.txtActionButton;
      updateResponse.id = this.siteVisit._id;
      updateResponse.result = this.siteVisitRes.txtActionButton;
      updateResponse.from = 'siteVisit';
    }
    this.dialogRef.close(updateResponse);
  }

  showActions = (fromWhich: String) => {
    let shouldShow: boolean;
    if (fromWhich === 'siteVisit') {
      shouldShow = this.siteVisitRes.txtActionButton.length > 0;
    } else {
      shouldShow = this.workshopRes.txtActionButton.length > 0;
    }
    console.log(`shoudlShow:: ${shouldShow}`);
    return shouldShow;
  }

  onClickProposedStatusRequest = (type: string) => {
    const visitType=this.siteVisitType==='MANDATORY'?'MANDATORY':type;
    this.dialogRef.close({ result: 'ReSchedule', from: visitType});
  }

  getTitle = () => {
    let title = 'Optional Workshop/SiteVisit';
    if (this.workshop) {
      title = 'Optional Workshop'
    }
    if (this.isSiteVisit && this.siteVisitType == 'MANDATORY') {
      title = title === 'Optional Workshop' ? `${title} And Mandatory SiteVisit` : 'Mandatory SiteVisit';
    } else {
      title = title === 'Optional Workshop' ? `${title}/SiteVisit` : 'Optional SiteVisit';
    }
    return title;
  }
}
