import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminServiceService } from 'src/app/admin/admin-service.service';
import { ClientData } from 'src/app/model/ClientData';
import { CoordinatorData } from 'src/app/model/CoordinatorData';
import { StatesData } from 'src/app/model/StatesData';
import { ProjectListService } from 'src/app/service/projectList.service';
import { DataSharingService } from '../services/data-sharing.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent {
  @Output() onFilter = new EventEmitter<any>();
  @Output() searchTextChanged = new EventEmitter<string>();
  @Input() onStatusChange: any

  roles: [];
  datas: any;
  CoordinatorSelected: string;
  roleSelected: string;
  ClientSelected: string;
  StateSelected: string;
  PaymentSelected: string;
  States: [StatesData];
  Coordinator: [CoordinatorData];
  Client: [ClientData];
  Payment: [];
  create_date_gt: string;
  create_date_lt: string;
  startDate: any;
  endDate: any;
  EndDate: any;
  result: any;
  userRole: any;
  userId: any;
  pageIndex: number = 0;
  pageSize: number = 5;
  totalItems: number = 0;
  role: string = '';
  projectList: any = [];
  coordinatorData:any = []
  coordinatorRole: any = [
    'All',
    'My Projects as Coordinator',
    'Site Visit as QC',
    'Site Visit as Coordinator',
    'Workshop as Coordinator',
  ];
  selectedFiles: File[];
  enteredSearchValue: string = '';
  private searchTextSubject = new Subject<string>();

  constructor(
    private service: AdminServiceService,
    private projectService: ProjectListService,
    private snackBars: MatSnackBar,
    private dataSharingService: DataSharingService
  ) { }

  ngOnInit() {
    this.GetAll();
    //List of Dropdowns
    this.userRole = this.dataSharingService.clientRole;
    this.userId = this.dataSharingService.clientId;
    this.service.getRole().subscribe((data) => {
      this.datas = data;
      this.roles = this.datas.data.roles;
    });
    this.service.getStates().subscribe((data) => {
      this.datas = data;
      this.States = this.datas.data;
    });
    // this.service.getCoordinator().subscribe((data) => {
    //   this.datas = data;
    //   this.Coordinator = this.datas.data;
    //   console.log('coordinator', this.Coordinator);
    // });
    this.service.getClient().subscribe((data) => {
      this.datas = data;
      this.Client = this.datas.data;
    });
    this.service.getPayment().subscribe((data) => {
      this.datas = data;
      this.Payment = this.datas.data;
    });
    this.searchTextSubject
      .pipe(
        debounceTime(1000), // Emit after 1 second of idle time
        distinctUntilChanged() // Only emit if the value has changed
      )
      .subscribe((value) => {
        this.onFilter.emit({ Name: value }); // Emit the event after the delay
      });
  }

  GetAll() {
    let params: any = {
      page: (+this.pageIndex + 1).toString(),
      list_size: this.pageSize.toString(),
      role: this.role,
      // state: this.state,
      // name: this.name,
      // project_coordinator: this.coordinator,
      // client: this.client,
      // created_date_gt: this.startDate,
      // created_date_lt: this.endDate,
      // status: this.status,
    };
    console.log('params', params);
    // this.getProjectList(params);
    this.projectService
      .getProjectListByClient(params)
      .subscribe((projectlistdata:any) => {
        let res;
        res = projectlistdata;
        this.projectList = res.data
        console.log(this.projectList);
        this.getCoordinators()
      });
  }

  getCoordinators(){
    const coordinatorsMap = new Map<string, { _id: string; name: string; username: string; role_name: string; }>();
    
    this.projectList.forEach((project: any) => {
        const coordinatorUsers = project.tagged_users.filter((user:any) => user.user?.role_name === 'Coordinator');
        coordinatorUsers.forEach((user:any) => {
            // Check if user ID is already present in the map
            if (!coordinatorsMap.has(user.user._id)) {
                coordinatorsMap.set(user.user._id, user.user);
            }
            console.log(coordinatorUsers)
        });
    });
    
    // Convert the map values to an array
    const coordinators = Array.from(coordinatorsMap.values());
    
    this.coordinatorData = coordinators;
    console.log("Coordinator data:", this.coordinatorData);
    this.Coordinator = this.coordinatorData;
}

  onRoleSelected() {
    this.onFilter.emit({ Role: this.roleSelected });
  }

  onCoordinatorSelected() {
    console.log('coordinator selected');
    this.onFilter.emit({ Coordinator: this.CoordinatorSelected });
    console.log({ Coordinator: this.CoordinatorSelected });
  }

  onClientSelected() {
    this.onFilter.emit({ Client: this.ClientSelected });
  }

  onStateSelected() {
    this.onFilter.emit({ state: this.StateSelected });
  }

  onPaymentSelected() {
    this.onFilter.emit({ Payment: this.PaymentSelected });
  }

  downloadProjectList() {
    console.log(this.onStatusChange)
    let params: any = {
      role: this.roleSelected,
      project_coordinator: this.CoordinatorSelected,
      client: this.ClientSelected,
      state: this.StateSelected,
      created_date_gt: this.create_date_gt,
      created_date_lt: this.create_date_lt,
      status: this.onStatusChange
    };
    if (this.roleSelected === '' || this.roleSelected === undefined) {
      delete params['role'];
    }
    if (this.StateSelected === '' || this.StateSelected === undefined) {
      delete params['state'];
    }
    if (this.ClientSelected === '' || this.ClientSelected === undefined) {
      delete params['client'];
    }
    if (
      this.CoordinatorSelected == '' ||
      this.CoordinatorSelected === undefined
    ) {
      delete params['project_coordinator'];
    }
    if (this.create_date_gt === '' || this.create_date_gt === undefined) {
      delete params['created_date_gt'];
    }
    if (this.create_date_lt === '' || this.create_date_lt === undefined) {
      delete params['created_date_lt'];
    }
    console.log('hi', params);
    this.projectService.exportProjectList(params).subscribe({
      next: (response) => {
        console.log('Excell file download => ', response);
        const downloadUrl = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'data.csv';
        link.click();
        URL.revokeObjectURL(downloadUrl);
      },
      error: (err) => {
        console.log('Error => ', err);
      },
    });
  }

  clearIndividual(selected: string) {
    switch (selected) {
      case 'coordinator':
        this.CoordinatorSelected = '';
        this.onFilter.emit('clearcoordinator');
        break;
      case 'client':
        this.ClientSelected = '';
        this.onFilter.emit('clearclient');
        break;
      case 'state':
        this.StateSelected = '';
        this.onFilter.emit('clearstate');
        break;
      case 'searchvalue':
        this.enteredSearchValue = '';
        this.onFilter.emit('clearsearchvalue');
        break;
      case 'startDate':
        this.create_date_gt = '';
        this.create_date_lt = '';
        this.range.get('start')?.setValue(null);
        this.range.get('end')?.setValue(null);
        this.onFilter.emit('clearstartendDate');
        break;
      case 'endDate':
        this.create_date_gt = '';
        this.create_date_lt = '';
        this.range.get('start')?.setValue(null);
        this.range.get('end')?.setValue(null);
        this.onFilter.emit('clearstartendDate');
        break;
      case 'coordinatorRole':
        this.roleSelected = '';
        this.onFilter.emit('clearRole');
        break;
      default:
        break;
    }
  }

  clearFilter() {
    this.CoordinatorSelected = '';
    this.ClientSelected = '';
    this.StateSelected = '';
    this.PaymentSelected = '';
    this.enteredSearchValue = '';
    this.create_date_gt = '';
    this.create_date_lt = '';
    this.roleSelected = '';
    this.range.get('start')?.setValue(null);
    this.range.get('end')?.setValue(null);

    this.onFilter.emit('clear');
  }

  //search

  onSearchTextChanged() {
    console.log(this.enteredSearchValue);
    this.searchTextSubject.next(this.enteredSearchValue);
  }

  // onSearchTextChanged() {
  //   this.onFilter.emit({ Name: this.enteredSearchValue });
  // }

  //Datre Range

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  onStartDateChanged() {
    if (this.range.value.start) {
      // alert(this.range.value.start)
      let date = this.range.value.start.getDate();
      let month = this.range.value.start.getMonth();
      let year = this.range.value.start.getFullYear();
      this.create_date_gt = `${year}-${month + 1}-${date}`;
      this.onFilter.emit({ create_date_gt: this.create_date_gt });
    } else if (this.range.value.end) {
      // alert(this.range.value.start)
      let date = this.range.value.end.getDate();
      let month = this.range.value.end.getMonth();
      let year = this.range.value.end.getFullYear();
      this.create_date_lt = `${year}-${month + 1}-${date}`;
      this.onFilter.emit({ create_date_lt: this.create_date_lt });
    }
  }
  //Cahnge Date Format
  dateFormatChanger(val: any) {
    const myarr = val.split(' ');
    console.log(myarr);
  }

  //import Holiday
  // onFileChange(event: any) {
  //   this.selectedFiles = event.target.files;
  //   console.log(this.selectedFiles);
  //   if(this.selectedFiles.length>0){
  //     const result=confirm('Do you want to Import the File for Holiday?');
  //     if(result){
  //       for(let i=0;i<this.selectedFiles.length;i++){
  //         const file=this.selectedFiles[i];
  //         this.getUploadURL(file)
  //       }
  //     }else{
  //       this.selectedFiles=[];
  //     }
  //   }
  // }

  onFileChange(event: any) {
    this.selectedFiles = event.target.files;
    console.log(this.selectedFiles);

    if (this.selectedFiles.length > 0) {
      const allowedExtensions = ['.csv']; // Define allowed file extensions

      const filesToUpload = Array.from(this.selectedFiles);
      const invalidFiles = filesToUpload.some((file) => {
        const extension = file.name.split('.').pop()?.toLowerCase();
        return !allowedExtensions.includes(`.${extension}`);
      });

      if (invalidFiles) {
        this.snackBars.open('Please select only .csv files', 'Ok', {
          duration: 3000,
        });
        this.selectedFiles = [];
        return;
      }

      const result = confirm('Do you want to Import the File for Holiday?');
      if (result) {
        for (let i = 0; i < this.selectedFiles.length; i++) {
          const file = this.selectedFiles[i];
          this.getUploadURL(file);
        }
      } else {
        this.selectedFiles = [];
      }
    }
  }

  getUploadURL(file: any) {
    this.projectService.importHoliday(file).subscribe({
      next: (res) => {
        this.snackBars.open('Holiday File is imported', 'Ok', {
          duration: 3000,
        });
      },
      error: (err) => {
        console.error(err);
        this.snackBars.open(
          'Something went wrong! Check Date Format DD-MM-YYYY',
          'Ok',
          { duration: 3000 }
        );
      },
    });
  }
}
