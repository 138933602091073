import {App} from './workshop.model';

export class Coordinator {
  private _id: string

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}

export class Client {
  private _id: string

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }
}

export class UploadDocModel {
  private _app: App
  private _name: string;
  private _description: string;
  private _type: string;
  private _coordinator: Coordinator;
  private _client: Client;
  private _state: string;
  private _city: string;
  private _organization: string;
  private _site_area: string;
  private _built_up_area: string;


  constructor(app: App,
              name: string,
              description: string,
              type: string,
              coordinator: Coordinator,
              client: Client,
              state: string,
              city: string,
              organization: string,
              site_area: string,
              built_up_area: string) {
    this._app = app;
    this._name = name;
    this._description = description;
    this._type = type;
    this._coordinator = coordinator;
    this._client = client;
    this._state = state;
    this._city = city;
    this._organization = organization;
    this._site_area = site_area;
    this._built_up_area = built_up_area;
  }


  get app(): App {
    return this._app;
  }

  set app(value: App) {
    this._app = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get coordinator(): Coordinator {
    return this._coordinator;
  }

  set coordinator(value: Coordinator) {
    this._coordinator = value;
  }

  get client(): Client {
    return this._client;
  }

  set client(value: Client) {
    this._client = value;
  }

  get state(): string {
    return this._state;
  }

  set state(value: string) {
    this._state = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get organization(): string {
    return this._organization;
  }

  set organization(value: string) {
    this._organization = value;
  }

  get site_area(): string {
    return this._site_area;
  }

  set site_area(value: string) {
    this._site_area = value;
  }

  get built_up_area(): string {
    return this._built_up_area;
  }

  set built_up_area(value: string) {
    this._built_up_area = value;
  }
}
