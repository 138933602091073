import {Directive, HostListener, HostBinding, Output, EventEmitter, Input} from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})
export class FileDragNDropDirective {
 /* //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.background') private background = '#ffffff';
  @HostBinding('style.border') private borderStyle = '1px dashed !important';
  @HostBinding('style.border-color') private borderColor = '#B3B3B3';
  @HostBinding('style.border-radius') private borderRadius = '8px !important';

  constructor() {
  }

  @HostListener('dragover', ['$event'])
  public onDragOver = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'lightgray';
    this.borderColor = 'cadetblue';
    this.borderStyle = '1px solid !important';
    console.log(`onDragOver:: File choosed ${evt}`);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#ffffff';
    this.borderColor = '#B3B3B3';
    this.borderStyle = '1px dashed !important';
    console.log(`onDragLeave:: File choosed ${evt}`);
  }

  @HostListener('drop', ['$event'])
  public onDrop = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    this.borderColor = '#B3B3B3';
    this.borderStyle = '1px dashed !important';
    debugger;
    let files = evt.dataTransfer.files;
    let valid_files: Array<File> = files;
    this.filesChangeEmiter.emit(valid_files);
    console.log(`onDrop:: File choosed ${evt}`);
  }*/
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
