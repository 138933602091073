import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { ProjectListService } from './service/projectList.service';
import { NotificationService } from './shared/services/notification.service';
import { BasePathInterceptor } from './shared/services/BasePathInterceptor';
import { LoadingInterceptor } from './shared/services/app-loader/loading.interceptor';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { DatePipe } from '@angular/common';
import { CdkColumnDef } from '@angular/cdk/table';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { FourzerofourComponent } from './fourzerofour/fourzerofour.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { WorkshopSiteVisitComponent } from './coordinator/workshop-site-visit/workshop-site-visit.component';
import { NgxGaugeModule } from 'ngx-gauge';


export const apiServiceProviders = [
  NotificationService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BasePathInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true
  },
  {
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }
];

@NgModule({
  declarations: [
    AppComponent,
    FourzerofourComponent,
    ForbiddenComponent,
    WorkshopSiteVisitComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    AngularEditorModule,
    NgxGaugeModule,
    AuthModule.forRoot({
      domain: environment.authDomain,
      clientId: environment.authClientId,
      authorizationParams: {
        audience: environment.audience,
        redirect_uri: window.location.origin
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [ProjectListService, apiServiceProviders, DatePipe, CdkColumnDef],
  bootstrap: [AppComponent]
})
export class AppModule { }
