import { Component } from '@angular/core';

@Component({
  selector: 'app-site-visit-meeting',
  templateUrl: './site-visit-meeting.component.html',
  styleUrls: ['./site-visit-meeting.component.scss']
})
export class SiteVisitMeetingComponent {

}
