<div class="workshop-container-wrapper__row">
  <div class="workshop-container-wrapper__row__header">
    <div class="workshop-container-wrapper__row__header-title">
      <label class="workshop-container-wrapper__row__header-title__name">Anshu Yadav</label>
      <label class="workshop-container-wrapper__row__header-title__desc">Site visit Request</label>
    </div>
  </div>
  <div class="workshop-container-wrapper__row__detail">
    <div class="workshop-container-wrapper__row__detail_schedule_container">
      <div *ngIf="!isRescheduleClicked" class="workshop-container-wrapper__row__detail_schedule_container_time">
        <img class="workshop-container-wrapper__row__detail_schedule_time__icon" src="assets/images/clock.svg"/>
        <label class="workshop-container-wrapper__row__detail_schedule_time__text">Oct 17, 2018 10:30 am - 11:30am</label>
      </div>
      <div *ngIf="isRescheduleClicked" class="workshop-container-wrapper__row__detail_schedule_container_new_proposed_time">
        <app-griha-calendar></app-griha-calendar>
      </div>
    </div>

    <div class="workshop-container-wrapper__row__detail_schedule_visitors">
      <div class="workshop-container-wrapper__row__detail_schedule_visitors__group">
        <img class="workshop-container-wrapper__row__detail_schedule_visitors__group__icon"
             src="assets/images/workshop_group.svg"/>
        <div class="workshop-container-wrapper__row__detail_schedule_visitors__group__chips">
          <mat-chip-listbox>
            <mat-chip-option>Riya Sharma</mat-chip-option>
            <mat-chip-option>Mayank</mat-chip-option>
            <mat-chip-option>Ayushi</mat-chip-option>
            <mat-chip-option>+3</mat-chip-option>
          </mat-chip-listbox>
        </div>
      </div>
      <div class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container">
        <button *ngIf="!isScheduleClicked"
                class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__scheduled"
                (click)="onClickSchedule()">ReScheduled
        </button>
        <div *ngIf="isScheduleClicked"
             class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__actions">
          <button mat-icon-button
                  class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__actions__cross"
                  (click)="onClickCross()">
            <img
              class="icon"
              src="assets/images/workshop_cross.svg"/>
          </button>
          <button mat-icon-button
                  class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__actions__checked"
                  (click)="onClickChecked()">
            <img
              class="icon"
              src="assets/images/workshop_checked.svg"/>
          </button>
          <button mat-icon-button
                  class="workshop-container-wrapper__row__detail_schedule_visitors-actions-container__actions__reschedule"
                  (click)="onClickReSchedule()">
            <img
              class="icon"
              src="assets/images/workshop_reschedule.svg"/>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>
