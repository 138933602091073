<div class="project-filter-container" *ngIf="filterNames.length > 0">
  <mat-button-toggle-group
    class="project-toggle__group"
    appearance="legacy"
    name="fontStyle"
    aria-label="Font Style">

    <mat-button-toggle
      *ngFor="let name of filterNames"
      (change)="onChangeFilter($event, name)"
      [ngClass]="{'bg-green':selected}">
      {{ name }}
    </mat-button-toggle>

  </mat-button-toggle-group>
</div>
