<!-- <span *ngIf="audit" class="project-name">{{audit[0]?.project.name}}</span>
<div >
  <mat-card *ngFor="let item of audit" class="activity-card">
    <mat-card-content class="content">
      
       {{item.project_id}}
        {{item.user_id}} 
      <mat-icon class="calendar">calendar_today</mat-icon>
      <p class="date">{{ item.created_at | dateFormat }}</p>
      <br />
  
      <div class="audit-log-desc">
        <span class="title"><mat-icon class="playlist">playlist_add_check</mat-icon><span>{{ item.title }}</span></span>
        <span class="name">{{ item?.by_user?.name }}</span>
      </div>
  
      <br />
       <mat-icon>person</mat-icon> 
      <ul class="desc">
        <li>
          {{ item.description }}
        </li>
      </ul>
    </mat-card-content>
  </mat-card>
</div> -->


<!-- <span class="project-name"></span> -->
<div>
  <mat-card class="activity-card">
    <mat-card-content class="content">
      <!-- {{item.project_id}}
        {{item.user_id}} -->
      <div class="main-container" *ngFor="let item of response; let i = index" [ngClass]="{'white-background': i % 2 === 0, 'grey-background': i % 2 !== 0}">

        <div class="data-div">
          <img src="/assets/images/Calendar.png" alt="">
          <!-- <mat-icon class="calendar">calendar_today</mat-icon> -->
          <div class="date">{{ formatDate(item.created_at) }}</div>
        </div>

        <div class="audit-log-desc">
          <div>
            <div class="main-title">
              <img src="/assets/images/non-verified-legal-document.png" alt="">
              <!-- <mat-icon class="playlist">playlist_add_check</mat-icon> -->
              <div>{{item.title}}</div>
            </div>
          </div>
          <span class="name">{{item?.by_user_name}}</span>
        </div>
        <!-- <mat-icon>person</mat-icon> -->
        <div class="description-div">
          <ul class="desc">
            <li>{{item?.description}}</li>
          </ul>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>