import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateFormatWtTime'
})
export class DateFormatWtTimePipe implements PipeTransform {

  transform(value: string): string {
    // Assuming value is in the format "2023-10-06T04:24:48.500Z"
    const formattedDate = moment(value).format('DD-MMM-YYYY');
    console.log(formattedDate);
    return formattedDate;
  }

}
