import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor() {}

  private userId: any;
  private userName: any;
  private assignCoordinatorId: any;

  setUserId(data: any) {
    console.log('data',data)
    this.userId = data;
  }

  getUserId() {
    return this.userId;
  }

  // setCoordinatorId(coordinatorId:any){
  //   this.assignCoordinatorId = coordinatorId
  //   console.log('data service', this.assignCoordinatorId)
  // }

  // getCoordinatorId(){
  //   return this.assignCoordinatorId
  // }

  setUserName(userName: any) {
    this.userName = userName;
  }

  getUserName() {
    return this.userName;
  }
}
