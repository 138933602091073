import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToRemainingDays',
})
export class DateToRemainingDaysPipe implements PipeTransform {
  transform(value: string): number {
    if (value === null || value === undefined) {
      return 0;
    } else {
      const today = new Date();
      const userDate = new Date(value);
      console.log(userDate, value);
      // Check if the user entered a valid date
      if (!isNaN(userDate.getTime())) {
        const timeDiff = userDate.getTime() - today.getTime();
        const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return remainingDays;
      } else {
        // Handle invalid date input
        return 0;
      }
    }
  }
}
