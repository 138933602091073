<!-- <button (click)="getToken()">get Token</button> -->
<!-- <ng-container *ngIf="auth.isAuthenticated$ | async; else loggedOut">
</ng-container>

<ng-template #loggedOut>
  <button (click)="auth.loginWithRedirect()">Log in</button>
</ng-template> -->

<!-- <div *ngIf="auth.isAuthenticated$ | async"> -->

  <div *ngIf="isAuthenticated" class="main-app" #mainApp>
    <router-outlet></router-outlet>
  </div>
  
  <div class="loader-overlay" *ngIf="loaderService.isLoading | async">
    <div class="loader">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>
  