import { Component } from '@angular/core';

@Component({
  selector: 'app-workshop-meeting',
  templateUrl: './workshop-meeting.component.html',
  styleUrls: ['./workshop-meeting.component.scss']
})
export class WorkshopMeetingComponent {

  isScheduleClicked: boolean = false;
  isRescheduleClicked: boolean | false;
  startOfWeek: Date | null;
  endOfWeek: Date | null;

  constructor() {
  }

  onClickSchedule = () => {
    this.isScheduleClicked = true;
  }

  onClickCross = () => {
    this.isScheduleClicked = false;
  }

  onClickChecked = () => {
    this.isScheduleClicked = false;
  }

  onClickReSchedule = () => {
    this.isScheduleClicked = false;
    this.isRescheduleClicked = true;
  }
}
