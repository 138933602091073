<div class="action-wrapper">
  <button *ngIf="canView" class="action-wrapper__icon-button" matTooltip="View" (click)="onViewAction()">
    <img class="icon" src="assets/images/btn_view_active.svg" />
  </button>
  <button *ngIf="canEdit" class="action-wrapper__icon-button" matTooltip="Edit" (click)="onEditAction()">
    <img class="icon" src="assets/images/btn_edit_action.svg" />
  </button>
  <button *ngIf="canDelete" class="action-wrapper__icon-button" matTooltip="Delete" (click)="onDeleteAction()">
    <img class="icon" src="assets/images/btn_delete_action.svg" />
  </button>
  <button class="action-wrapper__icon-button" *ngIf="canInitiateProject" (click)="initiateProject()">
    <mat-icon>slideshow</mat-icon>
  </button>

  <button class="action-wrapper__icon-button" matTooltip="Assign User" *ngIf="canAssignCoordinator" (click)="assignCoordinator()">
    <img src="/assets/images/person_add.svg" />
  </button>

  <button class="action-wrapper__icon-button history-icon" matTooltip="View Logs" *ngIf="canViewLogs" (click)="viewLogs()">
    <mat-icon>history</mat-icon>
  </button>

  <!-- && userRole==='super-admin' -->
  <button *ngIf="canViewSettings && (userRole==='Super-Admin' || userRole==='Admin')" class="action-wrapper__icon-button" matTooltip="View Settings" (click)="onViewSettings()">
    <img class="icon" src="assets/images/settings.svg" />
  </button>
</div>

<mat-drawer #drawer class="activity-log" mode="side" position="end">
  <button mat-button (click)="drawer.toggle()" class="activity-btn">
    Close
  </button>

  <p class="head">Activity History</p>
  <app-view-activity-logs [projectData]="rowData.project_code"></app-view-activity-logs>
</mat-drawer>