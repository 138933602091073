import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Moment } from 'moment';
import { ProjectListService } from 'src/app/service/projectList.service';

@Component({
  selector: 'app-extend-request',
  templateUrl: './extend-request.component.html',
  styleUrls: ['./extend-request.component.scss'],
})
export class ExtendRequestComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectListService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ExtendRequestComponent>
  ) {}
  days: any[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  selectedDays: number = 0;
  allowAfterDeadline: boolean;
  evaluatorTargetDate: any;
  remainingDays: number;
  ngOnInit() {
    console.log(this.data.projectDetails);
    this.evaluatorTargetDate = this.data.projectDetails.evaluator_target_date;
  }

  sendRequest() {
    const body = {
      evaluator_propose_day: this.selectedDays,
      evaluator_propose_requested: true,
    };
    this.projectService
      .extensionRequest(this.data.projectDetails.project_code, body)
      .subscribe({
        next: (res) => {
          this.snackBar.open('Requested for extension of deadline','Ok',{duration:3000});
          this.dialogRef.close({ update: true });
        },
        error: (err) => {
          console.error(err);
          this.snackBar.open('something went wrong!', 'Ok',{duration:3000});
        },
      });
  }

  calculateRemainingDays() {
    // Get the current date
    const currentDate = new Date();
    const userDate = new Date(this.evaluatorTargetDate);
    console.log(userDate);
    if (!isNaN(userDate.getTime())) {
      console.log('inside');
      const timeDiff = userDate.getTime() - currentDate.getTime();
      const remainingDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      this.remainingDays = remainingDays;
    } else {
      // Handle invalid date input
      this.remainingDays = 0;
    }
  }

  onReject() {
    const body = {
      evaluator_propose_day: 0,
      evaluator_propose_requested: false,
      evaluator_propose_request_status: false,
    };
    this.projectService
      .extensionRequest(this.data.projectDetails.project_code, body)
      .subscribe({
        next: (res) => {
          this.snackBar.open('Requested for extension of deadline Rejected','Ok',{duration:3000});
          this.dialogRef.close({ update: true });
        },
        error: (err) => {
          console.error(err);
          this.snackBar.open('something went wrong!', 'Ok',{duration:3000});
        },
      });
  }

  onAccept() {
    this.calculateRemainingDays();
    let newDate=new Date();
    if(this.remainingDays>0){
      newDate=new Date(this.data.projectDetails.evaluator_target_date);
    }
    let propose_day=this.data.projectDetails.evaluator_propose_day;
    newDate.setDate(newDate.getDate()+propose_day);
    const body = {
      evaluator_propose_day: 0,
      evaluator_propose_requested: false,
      evaluator_propose_request_status: true,
      evaluator_target_date:newDate
    };
    this.projectService
      .extensionRequest(this.data.projectDetails.project_code, body)
      .subscribe({
        next: (res) => {
          this.snackBar.open('Requested for extension of deadline Accepted','Ok',{duration:3000});
          this.dialogRef.close({ update: true });
        },
        error: (err) => {
          console.error(err);
          this.snackBar.open('something went wrong!', 'Ok',{duration:3000});
        },
      });
  }
}
