import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SideMenuComponent implements OnInit {
  @Input() menus: any[];

  constructor() {

  }

  ngOnInit(): void {
    this.menus.forEach((ele: string) => {
      console.log("url ", ele.link)
    })
  }
  route(e: any) {
    console.log(e)
  }

  /*onMenuExpand(targetMenu: any): void {
    this.menus.forEach(element => {
      element.open = false;
    });
    const idx = this.menus.findIndex(item => item.name === targetMenu.menuName);
    this.menus[idx].open = targetMenu.isOpen;
    this.menus = JSON.parse(JSON.stringify(this.menus));
  }*/
}
