import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  // A BehaviourSubject is an Observable with a default value
  public isLoading = new BehaviorSubject<boolean>(false);

  constructor() {
  }

}
