import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataSharingService } from '../shared/services/data-sharing.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectListService {
  private params: any;
  private PROJECT_URL = '/project';
  private PROJECT_PAGE_ONE_URL = '/project';
  private PROJECT_STATUS_URL = '/project?status=';
  private PROJECT_EXPORT_URL = '/project/exportProject';
  private ROLE: any;
  private GET_UPLOAD_HOLIDAY_URL = '/app/importholiday';
  private PROJECT_PATCH_URL = '/project/#';

  constructor(
    private http: HttpClient,
    private dataSharing: DataSharingService
  ) {
    this.params = new HttpParams();
  }

  public extensionRequest(project_code: string, body: any) {
    return this.http.patch(
      this.PROJECT_PATCH_URL.replace('#', project_code),
      body
    );
  }

  public getProjectList(params: any) {
    console.log('params', params);
    return this.http.get(this.PROJECT_PAGE_ONE_URL, { params: params });
  }

  public getFilteredList(status: string) {
    console.log('params', status);
    return this.http.get(`${this.PROJECT_STATUS_URL}${status}`);
  }

  //patch Api

  public initiateProject(id: string, body: JSON) {
    console.log('params', body);
    return this.http.patch(`${this.PROJECT_URL}/${id}`, body);
  }

  public rejectProject(id: string, body: JSON) {
    return this.http.patch(`${this.PROJECT_URL}/${id}`, body);
  }

  public getProjectListByClient(params: any) {
    console.log('params', params);
    // let param = new HttpParams().set('id', id);
    return this.http.get(this.PROJECT_URL, { params: params });
  }

  public exportProjectList(params: any) {
    //let headers = new HttpHeaders({ Accept: 'text/csv' });
    return this.http.get(this.PROJECT_EXPORT_URL, {
      responseType: 'blob',
      params: params,
    });
  }

  public filterData(param: any) {
    console.log('params', param);
    this.ROLE = this.dataSharing.clientRole;
    if (param == 'clear') {
      this.params = new HttpParams();
      if (this.ROLE === 'Client' || this.ROLE === 'Coordinator') {
        this.params = this.params.append('status', 'INITIATED');
      } else {
        const params = {
          page: 1,
          list_size: 5,
        };
        return this.getProjectList(params);
      }
    }

    if (param.state) {
      this.params = this.params.append('state', param.state.toString());
    }
    if (param.Coordinator) {
      this.params = this.params.append(
        'project_coordinator',
        param.Coordinator.toString()
      );
    }

    if (param.Client) {
      this.params = this.params.append('client', param.Client.toString());
    }
    if (param.Payment) {
      this.params = this.params.append('Payment', param.Payment.toString());
    }
    if (param.Name) {
      this.params = this.params.append('name', param.Name.toString());
    }
    if (param.create_date_gt) {
      this.params = this.params.append(
        'create_date_gt',
        param.create_date_gt.toString()
      );
    }
    if (param.create_date_lt) {
      this.params = this.params.append(
        'create_date_lt',
        param.create_date_lt.toString()
      );
    }
    if (param.status) {
      this.params = new HttpParams();
      this.params = this.params.append(
        'status',
        param.status.toString().toUpperCase()
      );
    }
    // if (this.ROLE === 'Client' || this.ROLE === 'Coordinator') {
    //   this.params = this.params.append('status', 'INITIATED')
    // }
    console.log(this.params);
    return this.http.get(this.PROJECT_URL, { params: this.params });
  }

  getUploadHolidayURL() {
    return this.http.post(this.GET_UPLOAD_HOLIDAY_URL, {});
  }

  uploadHoliday(URL: string, file: any) {
    const headers = { 'Content-Type': 'image/jpg' };
    return this.http.put(URL, file, { headers });
  }

  importHoliday(file: File) {
    const formData: FormData = new FormData();
    formData.append('holidays', file, file.name);

    return this.http.post(this.GET_UPLOAD_HOLIDAY_URL, formData);
  }
}
