<div class="calendar__wrapper">
  <div class="calendar__input-wrapper" (click)="show = !show">
    <input readonly class="calendar__input" [(ngModel)]="selectedDate">
    <img class="icon_calendar" src="/assets/images/calendar_black.svg" alt="calendar icon" />
  </div>
  <div class="calendar" [class.calendar-bottom]="shouldApply" *ngIf="show" [ngClass]="calCalendarPos()" #calendar>
    <div class="calendar__navs">
      <div class="calendar__month-nav">
        <button *ngIf="isDisabledMonth(currentDate)"
                class="calendar__nav-btn" (click)="prevMonth()"
                [disabled]="!isDisabledMonth(currentDate)">
          <img src="/assets/images/left_arrow.svg">
        </button>
        <span class="calendar__month-nav__name-text">
          {{currentDate.format('MMMM').toUpperCase()}}  {{currentDate.format('YYYY')}}
        </span>
        <button class="calendar__nav-btn" (click)="nextMonth()">
          <img src="/assets/images/right_arrow.svg">
        </button>
      </div>
    </div>

    <div class="calendar__month">
      <div class="calendar__day-names">
        <div class="calendar__day-name" *ngFor="let name of namesOfDays">{{name}}</div>
      </div>
      <div class="calendar__weeks">
        <div class="calendar__week" *ngFor="let week of weeks">
          <ng-container *ngFor="let day of week">
            <div *ngIf="!disableWeekSelection" class="calendar__date"
                 [ngClass]="{
                'disabled': isSatSundayOrCurrentWeekPlusThreeWeekOrPreviousDay(day.mDate),
                'prev': !isSelectedMonth(day.mDate),
                'today': day.today,
                'selected': day.selected
              }" (click)="selectDate(day)">

           <!-- <span class="calendar__date&#45;&#45;name"
                  [ngClass]="{
                'disabled': isSatSundayOrCurrentWeekPlusThreeWeekOrPreviousDay(day.mDate)}">
              {{getDayNameByDate(day.mDate)}}
            </span>-->
              <span class="calendar__date--name"
                    [ngClass]="{
                'disabled': isSatSundayOrCurrentWeekPlusThreeWeekOrPreviousDay(day.mDate)}">
              {{day.mDate.date()}}
            </span>
            </div>
            <div *ngIf="disableWeekSelection" class="calendar__date"
                 [ngClass]="{'today': day.today, 'selected': day.selected, 'disabled': !isSelectedWeek(day.mDate)}"
                 (click)="selectDateDailyCal(day)">
              <span class="calendar__number">{{day.mDate.date()}}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
