<div class="ws-request-status-wrapper">
  <button class="close" mat-button (click)="onClickedClose('close')">
    <mat-icon>close</mat-icon>
  </button>
  <div mat-dialog-content class="ws-request-status-wrapper__content">
    <label class="ws-request-status-wrapper__text">Request Submitted Successfully!</label>
    <div class="ws-request-status-wrapper__content--status">
      <span class="ws-request-status-wrapper__click-text"><a style="color: blue;" [routerLink]="" (click)="onClickViewStatus()">click</a></span>
      <span class="ws-request-status-wrapper__non-click-text">to view status</span>
    </div>
  </div>
</div>
