import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent {
  @Output() onTextEntered = new EventEmitter<string>();
  @Output() blurEvent = new EventEmitter<any>();
  @Input() editorContent: string = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['subscript', 'superscript'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      ['indent', 'outdent'],
      ['heading', 'fontName',  'backgroundColor'],
      ['insertImage', 'insertVideo', 'insertHorizontalRule'],
      ['link', 'unlink', 'removeFormat'],
      ['toggleEditorMode'],
      ['strikeThrough'],
    ],
  };

  editorData(newData: string) {
    // this.onTextEntered.emit(this.editorContent);
    this.editorContent = newData;
    this.onTextEntered.emit(newData);
  }

  onBlur(e:any){
    this.blurEvent.emit(e)
  }
}
