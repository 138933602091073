import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserData} from '../../../model/UserData';
import {ProjectModel} from '../../../model/notification/project.model';
import {ResponsePacket} from '../../../model/ResponsePacket';
import {ProjectDetails} from '../../../model/project.details.module';

@Injectable({
  providedIn: 'root',
})
export class ProjectAndPersonalDetailService {
  private API_URL = '/user';
  private PROJECT_URL = '/project';
  private STATE_URL='/state';
  private BUILDING_URL='/building/#'
  private BUILDING_DELETE_ALL_URL='/building/all/#'
  private UPDATE_BUILDING_URL='/project/#'
  private CITY_URL='/app/city'
  private ResetCalculator='/appraisalfield/resetvalue/#'

  constructor(private http: HttpClient) {}

  
  //Get list of States
  public getStates() {
    return this.http.get(this.STATE_URL);
  }

  public getCity(){
    return this.http.get(this.CITY_URL)
  }

  getUserDetails(userId: number): Observable<UserData> {
    const url = `${this.API_URL}/${userId}`;
    return this.http.get<UserData>(url);
  }

  getUserByEmail(email: string): Observable<UserData>{
    const params={
      email:email
    }
    return this.http.get<UserData>(this.API_URL,{params:params})
  }

  getUserByUsername(username:string):Observable<UserData>{
    const params={
      username:username
    }
    return this.http.get<UserData>(this.API_URL,{params:params})
  }

  updateUser(user: any): Observable<UserData> {
    const url = `${this.API_URL}/update/${user._id}`;
    return this.http.patch<UserData>(url, user);
  }

  getProjectDetail(projectCode: number): Observable<ResponsePacket<ProjectDetails[]>> {
    let opts = new HttpParams();
    if (projectCode) {
      opts = opts.append('project_code', projectCode.toString());
    }
    return this.http.get<ResponsePacket<ProjectDetails[]>>(this.PROJECT_URL, {params: opts});
  }

  updateProject(project: any,project_id:string): Observable<ProjectModel> {
    const url = `${this.PROJECT_URL}/${project_id}`;
    return this.http.patch<ProjectModel>(url, project);
  }

  updateConsultant(projectId: any, consultantUsername: any) {
    const body = {
      consultant: {
        username:consultantUsername
      },
      // evaluator: evaluatorId,
    };
    return this.http.patch(this.PROJECT_URL + `/${projectId}`, body);
  }

  submitCoordinator(projectId:any, coordinatorId:any){
    const body = {
      coordinator: coordinatorId
    }
    return this.http.patch(this.PROJECT_URL + `/${projectId}`, body);
  }

  saveBuildingDetails(body:any,project_id:string){
    return this.http.post(this.BUILDING_URL.replace('#',project_id),body);
  }

  updateBuildingDetails(body:any,project_code:string){
    return this.http.patch(this.UPDATE_BUILDING_URL.replace('#',project_code),body);
  }

  deleteBuildings(projectId:string){
    return this.http.delete(this.BUILDING_DELETE_ALL_URL.replace('#',projectId));
  }

  resetCalculator(projectId:string){
    return this.http.patch(this.ResetCalculator.replace('#',projectId),{});
  }
}
