<div class="notification">
  <div class="notification-header">
    <label class="notification-header__text">Notifications</label>
    <!-- <button class="notification-header__btn-read" (click)="onClickMarAllRead()">Mark All as Read </button> -->
    <img src="/assets/images/bin.svg" class="notification-header__btn-read" (click)="onClickMarAllRead()" title="Mark All as Read">
  </div>
  <div class="notification-body">
    <div *ngFor="let notification of notifications;" class="notification-body__row">
      <!--<div class="notification-body__row--profile-pic">
        <div class="notification-body__row--profile-pic___img">
          <img
            class="icon"
            src="assets/images/profile.png"
          />
        </div>
        <label *ngIf="isNotRead(notification)" class="notification-body__row--profile-pic___dot"></label>
      </div> -->
      <div class="notification-body__row--content" *ngIf="notification.status==='NEW'">
        <div class="notification-body__row--content___heading-section">
          <div  (click)="onClickNotification(notification)" class="notification-body__row--content-section___heading">
            <span>
            {{notification.title}}
          </span>
          <span class="notification-body__row--content-section___heading_desc">
            {{notification.description}}
          </span>
        </div>
          <!-- <button class="notification-body__row--content-section___markasread"
                  (click)="onClickMarkAsRead(notification)">
            Mark as Read
          </button> -->
          <img src="/assets/images/bin.svg" title="Mark as Read" class="notification-body__row--content-section___markasread" (click)="onClickMarkAsRead(notification)">
        </div>
        <div class="notification-body__row--content___time">{{notification.created_at | dateFormat}}</div>
      </div>
      <div class="notification-body__row--content-old" *ngIf="notification.status==='READ'">
        <div class="notification-body__row--content___heading-section-old">
          <div  (click)="onClickNotification(notification)" class="notification-body__row--content-section___heading-old">{{notification.title}}</div>
          <button class="notification-body__row--content-section___markasread-old"
                  (click)="onClickMarkAsNew(notification)">
            Mark as New
          </button>
        </div>
        <div class="notification-body__row--content___time-old">{{notification.created_at | dateFormat}}</div>
      </div>
    </div>
  </div>
</div>
