<div class="view-status-wrapper">

  <div class="view-status-wrapper__header" *ngIf="workshop">
    <label class="view-status-wrapper__header--title">{{workshop.name}}</label>
    <label class="view-status-wrapper__header--value">{{getTitle()}}</label>
  </div>

  <div class="view-status-wrapper__workshop" *ngIf="workshop">
    <div class="view-status-wrapper__workshop--schedule-status">
      <div class="view-status-wrapper__workshop--schedule-status-wrapper">
        <label class="view-status-wrapper__workshop--schedule-status-wrapper__title">Workshop:</label>
        <div class="view-status-wrapper__workshop--schedule-status-wrapper__value">
          <span class="view-status-wrapper__workshop--schedule-status-wrapper__value--text">{{workshopRes?.res?.workshopWeek}}</span>
          <span
            class="view-status-wrapper__workshop--schedule-status-wrapper__value--remaining_days">{{workshopRes?.res?.remainingDays}} days from today</span>
        </div>
      </div>
      <label  [ngClass]="(workshopRes?.status === 'Confirmed' || workshopRes?.status === 'Done') ? 'view-status-wrapper__workshop--schedule-status--text_confirm' : 'view-status-wrapper__workshop--schedule-status--text'">{{workshopRes?.status}}</label>
    </div>

    <div class="view-status-wrapper-row">
      <label class="view-status-wrapper-row__title">Client Representative Email</label>
      <div class="view-status-wrapper-row__value-container">
        <label class="view-status-wrapper-row__value">{{workshop.client_representative_email}}</label>
      </div>
    </div>
    <div class="view-status-wrapper-row">
      <label class="view-status-wrapper-row__title">Client Representative Phone No.</label>
      <div class="view-status-wrapper-row__value-container">
        <label class="view-status-wrapper-row__value">{{workshop.client_representative_phone}}</label>
      </div>
    </div>
    <div class="view-status-wrapper-row">
      <label>Attendees</label>
      <div>
        <app-meeting-attendees [attendees]="workshop.attendees"></app-meeting-attendees>
      </div>
    </div>

    <div class="view-status-wrapper-bottom" *ngIf="showActions('workshop')">
      <button *ngIf="!workshopRes.canReschedule" class="view-status-wrapper-bottom__cancel" (click)="onCancelRequest('cancel')">Cancel</button>
      <button *ngIf="workshopRes.canReschedule" class="view-status-wrapper-bottom__schedule" (click)="onClickProposedStatusRequest('workshop')">
        Reschedule
      </button>
      <button class="view-status-wrapper-bottom__schedule" (click)="onClickScheduleRequest('workshop')">
        {{ workshopRes.txtActionButton }}
      </button>
    </div>

  </div>

  <hr class="view-status-wrapper__divider" *ngIf="workshop && siteVisit">

  <div class="view-status-wrapper__site-visit" *ngIf="siteVisit">
    <div class="view-status-wrapper__site-visit--schedule-status">
      <div class="view-status-wrapper__site-visit--schedule-status-wrapper">
        <label class="view-status-wrapper__site-visit--schedule-status-wrapper__title">Site Visit:</label>
        <div class="view-status-wrapper__site-visit--schedule-status-wrapper__value">
          <span class="view-status-wrapper__site-visit--schedule-status-wrapper__value--text">{{siteVisitRes?.res?.workshopWeek}}</span>
          <span
            class="view-status-wrapper__site-visit--schedule-status-wrapper__value--remaining_days">{{siteVisitRes?.res?.remainingDays}} days from today</span>
        </div>
      </div>
      <label [ngClass]="(siteVisitRes?.status === 'Confirmed' || siteVisitRes?.status === 'Done') ? 'view-status-wrapper__site-visit--schedule-status--text_confirm' : 'view-status-wrapper__site-visit--schedule-status--text'">{{siteVisitRes.status}}</label>
    </div>

    <div class="view-status-wrapper-row">
      <label class="view-status-wrapper-row__title">Client Representative Email</label>
      <div class="view-status-wrapper-row__value-container">
        <label class="view-status-wrapper-row__value">{{siteVisit.client_representative_email}}</label>
      </div>
    </div>
    <div class="view-status-wrapper-row">
      <label class="view-status-wrapper-row__title">Client Representative Phone No.</label>
      <div class="view-status-wrapper-row__value-container">
        <label class="view-status-wrapper-row__value">{{siteVisit.client_representative_phone}}</label>
      </div>
    </div>
    <div class="view-status-wrapper-bottom" *ngIf="showActions('siteVisit')">
      <button *ngIf="!siteVisitRes.canReschedule" class="view-status-wrapper-bottom__cancel" (click)="onCancelRequest('cancel')">Cancel</button>
      <button *ngIf="siteVisitRes.canReschedule" class="view-status-wrapper-bottom__schedule" (click)="onClickProposedStatusRequest('siteVisit')">
        Reschedule
      </button>
      <button class="view-status-wrapper-bottom__schedule" (click)="onClickScheduleRequest('siteVisit')">
        {{ siteVisitRes.txtActionButton }}
      </button>
    </div>
  </div>
</div>


